import { createAsyncThunk } from '@reduxjs/toolkit'
import { CIVILITY_DISPLAY, SEX_DISPLAY } from '../../locales/constants'

import { roleKey, tokenKey } from '../auth/utils'
import { requestModes } from '../appConfig/services'
import { AppTypes } from '../../utils/types/AppStatus'
import jsonToString from '../../utils/lib/jsonToString'
import { getAxios, getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'
import { clearSessionStorage, setSessionStorage } from '../../utils/security/localStorage'

import { requestManageUser } from '../user/services'
import { requestSetUsersRole } from '../users/services'
import { requestGetConfigInfos } from '../configuration/services'
import {
  requestInitSealingStatus,
  requestSetSealing,
  requestCreateSealMemberCredentials,
} from '../sealing/services'

import { initialState } from './redux'

const LIMIT = 20

// Users request by search or/and by roleIds
export const requestUsers = createAsyncThunk<any, any>(
  'demo/requestUsers',
  async ({ search, roles, params = {}, clear = true }, { rejectWithValue }) => {
    const response: any = await getAxios(
      `/users?${jsonToString({ search, roles, ...params, take: LIMIT })}`,
      getTokenHeaders(true)
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
        roles,
        clear,
      }
    }
  }
)

export const requestUsersStates = (builder) => {
  builder.addCase(requestUsers.fulfilled, (state, { payload }) => {
    const { data, clear, roles } = payload
    const { users, count } = data
    state.totalUsers = count

    if (clear) {
      state.userIds = []
      if (roles) {
        roles.forEach((role) => (state.userIdsByRole[role] = []))
      } else {
        state.userIdsByRole = {}
      }
    }
    users.forEach((user) => {
      const { id: userId, role } = user
      if (!state.userIds.includes(userId)) {
        state.userIds.push(userId)
      }
      if (!state.userIdsByRole[role]) {
        state.userIdsByRole[role] = []
      }
      if (!state.userIdsByRole[role].includes(userId)) {
        state.userIdsByRole[role].push(userId)
      }
      state.userById[userId] = {
        userId,
        username: user?.login,
        pic: user?.avatar,
        ...user,
        college: { type: `college_${user?.college?.type}` },
        sex: SEX_DISPLAY[user?.sex],
        civility: CIVILITY_DISPLAY[user?.civility],
      }
    })
  })
}

// Random Role User
export const requestRandomRole = createAsyncThunk<any, any>(
  'users/requestRandomRole',
  async ({ role, filters = {}, clear = false }) => {
    const response: any = await getAxios(
      `/users/random?${jsonToString({ ...filters, role })}`,
      getTokenHeaders(true),
      false
    )

    if (response.error) {
      return {
        clear,
        data: { role },
      }
    } else {
      return {
        clear,
        data: response.data || { role },
      }
    }
  }
)

export const requestRandomRoleStates = (builder) => {
  builder.addCase(requestRandomRole.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestRandomRole.fulfilled, (state, { payload: { data: user, clear } }) => {
    const { role, id: userId } = user
    state.isLoading = false

    if (userId) {
      if (clear || !state.userIdsByRoleSelection[role]?.length) {
        state.userIdsByRoleSelection[role] = [userId]
      } else if (!state.userIdsByRoleSelection[role]?.includes(userId)) {
        state.userIdsByRoleSelection[role] = [userId, ...state.userIdsByRoleSelection[role]]
      }
      state.userById[userId] = {
        userId,
        username: user?.login,
        pic: user?.avatar,
        ...user,
        college: { type: `college_${user?.college?.type}` },
        sex: SEX_DISPLAY[user?.sex],
        civility: CIVILITY_DISPLAY[user?.civility],
      }
    } else if (clear) {
      state.userIdsByRoleSelection[role] = []
    }
  })

  builder.addCase(requestRandomRole.rejected, (state) => {
    state.isLoading = false
  })
}

// Demo User Select
export const requestSelectUser = createAsyncThunk<any, any>(
  'users/requestSelectUser',
  async ({ userId }, { rejectWithValue }) => {
    const user: any = await getAxios(`/users/${userId}`, getTokenHeaders(true))

    if (user.error) {
      return rejectWithValue(user.error.response.status)
    }
    const role = user.data.role
    const response: any = await setAxios('/auth/demo/login', { userId }, getTokenHeaders(true))

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        ...response.data,
        role,
      }
    }
  }
)

export const requestSelectUserStates = (builder) => {
  builder.addCase(requestSelectUser.pending, (state, { meta: { arg } }) => {
    state.isLoading = true
    state.currentUserId = arg.userId
  })

  builder.addCase(requestSelectUser.fulfilled, (state, { payload: { role, access_token } }) => {
    state.isLoading = false
    setSessionStorage(roleKey, `ROLE_${role}`)
    setSessionStorage(tokenKey, access_token)
  })

  builder.addCase(requestSelectUser.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestStartDemo = createAsyncThunk<undefined, { errorCallback: Function }>(
  'users/requestStartDemo',
  async ({ errorCallback }, { dispatch, rejectWithValue }) => {
    const response: any = await setAxios('/simulation/start', {}, getTokenHeaders(true))
    if (response.error) {
      if (response.error.response.status === 400) {
        errorCallback(response.error.response.data.message)
      }
      if (response.error.response.status === 403) {
        errorCallback(response.error.response.data.template, response.error.response.data.params)
      }
      return rejectWithValue(response.error.response.status)
    } else {
      dispatch(requestGetConfigInfos())
      return response.data
    }
  }
)

export const requestStartDemoStates = (builder) => {
  builder.addCase(requestStartDemo.rejected, (state) => {
    state.isDemo = false
  })
  builder.addCase(requestStartDemo.fulfilled, (state) => {
    state.isDemo = true
  })
}

export const requestModesState = (builder) => {
  builder.addCase(requestModes.fulfilled, (state, { payload }) => {
    state.isDemo = payload.mode === AppTypes.DEMO
  })
}

export const requestStopDemo = createAsyncThunk<undefined, undefined>(
  'users/requestStopDemo',
  async (_, { dispatch }) => {
    const response: any = await setAxios('/simulation/stop', {}, getTokenHeaders(true))
    dispatch(requestSetSealing(true))
    dispatch(requestInitSealingStatus({ isAdmin: true }))
    dispatch(requestGetConfigInfos())
    return response.data
  }
)

export const requestStopDemoStates = (builder) => {
  builder.addCase(requestStopDemo.fulfilled, (state) => {
    clearSessionStorage()
    return {
      ...state,
      ...initialState,
      isDemo: false,
    }
  })
}

// In case of role change
const requestCheckUsers = (state, { payload }) => {
  const userIds = payload.userIds || [payload.id]
  if (!!userIds?.length) {
    const roleSelection = state.userIdsByRoleSelection
    Object.keys(roleSelection).forEach(
      (role) =>
        (state.userIdsByRoleSelection[role] = roleSelection[role].filter(
          (id) => !userIds.includes(id)
        ))
    )
  }
}

export const requestCheckUsersStates = (builder) => {
  builder.addCase(requestSetUsersRole.fulfilled, requestCheckUsers)
  builder.addCase(requestManageUser.fulfilled, requestCheckUsers)
}
