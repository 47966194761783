import { createAsyncThunk } from '@reduxjs/toolkit'
import { encryptAnyBallot, generateBallotPrint } from '../../utils/security/ballot.encrypt'
import { getAxios, getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'
import { setAppLoading, setErrorStatus } from '../app/redux'

export const requestPollResolution = createAsyncThunk<
  any,
  {
    pollId: string
  }
>('userResolution/requestPollResolution', async ({ pollId }, { rejectWithValue }) => {
  const response: any = await getAxios(`/res/resolutions?pollId=${pollId}`, getTokenHeaders())

  if (response.error) {
    return rejectWithValue(response.error.response.status)
  } else {
    return { data: response.data, pollId }
  }
})

export const requestPollResolutionStates = (builder) => {
  builder.addCase(requestPollResolution.fulfilled, (state, { payload: { pollId, data } }) => {
    state.isLoading = false
    state.pollsById[pollId] = []
    data?.forEach((item) => {
      if (!state.pollsById[pollId].includes(item.id)) {
        state.pollsById[pollId].push(item.id)
      }
      state.resolutionById[item.id] = item
      try {
        state.resolutionById[item.id].description = JSON.parse(item.description)
      } catch (errr) {}
    })
  })

  builder.addCase(requestPollResolution.rejected, (state) => {
    state.isLoading = false
  })
}

// Encrypted Ballot
const BALLOT_TYPES = {
  undefined: 'BLANK',
  null: 'ABSTENTION',
  true: 'YES',
  false: 'NO',
}

const votePending = {}

export const requestVoteResolution = createAsyncThunk<
  any,
  {
    resId: number
    vote: boolean | null | undefined
    pollId: string
    userId: string | number
    additionalParams: any
  }
>(
  'userResolutions/requestVoteResolution',
  async ({ vote, resId, pollId, userId, additionalParams = {} }, { rejectWithValue, dispatch }) => {
    if (votePending[userId]) return null
    votePending[userId] = true
    dispatch(setAppLoading(true))
    const response: any = await getAxios(`/encryption?pollId=${pollId}`, getTokenHeaders())

    if (response.error) {
      votePending[userId] = false
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error.response.data.message }))
      return rejectWithValue(response.error.response.status)
    }

    const encryptionKey = response.data
    const encryptedBallotContent = await encryptAnyBallot(
      {
        answer: BALLOT_TYPES[vote + ''],
      },
      encryptionKey
    )
    const print = await generateBallotPrint(encryptedBallotContent)

    const body: any = {
      print,
      resolutionId: resId,
      encryptedBallotContent,
      ...additionalParams,
    }
    if (userId !== 'me') {
      body.proxiedUserId = userId
    }

    const voteRes: any = await setAxios(`/res/ballots`, body, getTokenHeaders())

    votePending[userId] = false
    if (voteRes.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: voteRes.error.response.data.message }))
      return rejectWithValue(voteRes.error.response.status)
    }
    setTimeout(() => dispatch(requestPollResolution({ pollId })), 250)
    dispatch(setAppLoading(false))
    return {
      resId,
      pollId,
      print,
      encryptedBallot: encryptedBallotContent,
      data: voteRes.data,
      savePrints: userId === 'me',
    }
  }
)

export const requestVoteResolutionStates = (builder) => {
  builder.addCase(requestVoteResolution.fulfilled, (state, { payload }) => {
    const { pollId, resId, data, print, encryptedBallot, savePrints } = payload
    if (!payload) return
    if (savePrints) {
      state.ballots[resId] = {
        print,
        encryptedBallot,
        ...(data || {}),
      }
      if (!state.pollsBallots[pollId]) {
        state.pollsBallots[pollId] = []
      }
      if (!state.pollsBallots[pollId].includes(resId)) {
        // TODO: keep resolutions order when pushing resId
        state.pollsBallots[pollId].push(resId)
      }
    }
  })
}

export const requestVoteMultiResolutions = createAsyncThunk<
  any,
  {
    pollId: string
    selection: { [resId: number]: boolean | null | undefined }
    userId: string
    additionalParams: any
  }
>(
  'userResolutions/requestVoteMultiResolutions',
  async ({ pollId, selection, userId, additionalParams = {} }, { rejectWithValue, dispatch }) => {
    if (votePending[userId]) return null
    votePending[userId] = true
    dispatch(setAppLoading(true))
    const response: any = await getAxios(`/encryption?pollId=${pollId}`, getTokenHeaders())

    if (response.error) {
      votePending[userId] = false
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error.response.data.message }))
      return rejectWithValue(response.error.response.status)
    }

    try {
      const resolutions: Array<[string, boolean | null | undefined]> = Object.entries(selection)
      const encryptionKey: string = response.data

      const encryptedBallots: Array<{
        print: string
        resolutionId: number
        encryptedBallotContent: string
      }> = []
      for (let i = 0; i < resolutions.length; i++) {
        const [key, vote] = resolutions[i]
        const encryptedBallotContent = await encryptAnyBallot(
          {
            answer: BALLOT_TYPES[vote + ''],
          },
          encryptionKey
        )
        const print = await generateBallotPrint(encryptedBallotContent)
        encryptedBallots.push({
          print,
          resolutionId: parseInt(key),
          encryptedBallotContent,
        })
      }

      const body: any = {
        encryptedBallots,
        ...additionalParams,
      }
      if (userId !== 'me') {
        body.proxiedUserId = userId
      }
      const voteRes: any = await setAxios('/res/ballots/multi', body, getTokenHeaders())
      votePending[userId] = false
      if (voteRes.error) {
        dispatch(setAppLoading(false))
        dispatch(setErrorStatus({ message: voteRes.error.response.data.message }))
        return rejectWithValue(voteRes.error.response.status)
      }

      setTimeout(() => dispatch(requestPollResolution({ pollId })), 250)
      dispatch(setAppLoading(false))
      return {
        pollId,
        resolutions,
        encryptedBallots,
        data: voteRes.data,
        savePrints: userId === 'me',
      }
    } catch (err) {
      console.error(err)
    }
  }
)

export const requestVoteMultiResolutionsStates = (builder) => {
  builder.addCase(requestVoteMultiResolutions.fulfilled, (state, { payload }) => {
    if (!payload) return
    const { resolutions, pollId, data, encryptedBallots, savePrints } = payload
    savePrints &&
      resolutions.forEach(([resId], index) => {
        const myResId = parseInt(resId)
        const myPrint = data.ballotPrints.find((resolutionId) => resolutionId === myResId)
        state.ballots[resId] = {
          ...data,
          ...myPrint,
          ...encryptedBallots[index],
        }
        delete state.ballots[resId].ballotPrints
        if (!state.pollsBallots[pollId]) {
          state.pollsBallots[pollId] = []
        }
        if (!state.pollsBallots[pollId].includes(myResId)) {
          state.pollsBallots[pollId].push(myResId)
        }
      })
  })
}

// For not Anonymous mode only
export const requestAllResolutionBallot = createAsyncThunk<
  any,
  {
    pollId: string
  }
>(
  'userResolutions/requestAllResolutionBallot',
  async ({ pollId }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const response: any = await getAxios(`/res/ballots/me?pollId=${pollId}`, getTokenHeaders())
    dispatch(setAppLoading(false))
    if (response.error) {
      dispatch(setErrorStatus({ message: response.error.response.data.message }))
      return rejectWithValue(response.error.response.status)
    }
    return { pollId, data: response.data }
  }
)

export const requestAllResolutionBallotStates = (builder) => {
  builder.addCase(requestAllResolutionBallot.fulfilled, (state, { payload: { pollId, data } }) => {
    state.pollsBallots[pollId] = data.map((item) => {
      const resId = item.resolutionId
      state.ballots[resId] = {
        ...(state.ballots[resId] || {}),
        ...item,
      }
      return resId
    })
  })
}

// Set Present in progressive Resolution
export const requestPresentResolution = createAsyncThunk<any, undefined>(
  'userResolutions/requestPresentResolution',
  async (_, { rejectWithValue }) => {
    const response: any = await setAxios('/res/polls/ping', {}, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
  }
)
