// Hooks
import useLocalize from '../../../../../locales/hook'
import { useCallback, useEffect, useState } from 'react'
import { useUnseal } from '../../../../../redux/sealing/hooks'

// Components
import { IonModal } from '@ionic/react'
import Icon from '../../../../../atoms/Icon/Icon'
import Button from '../../../../../atoms/Button/Button'
import TextInput from '../../../../../atoms/TextInput/TextInput'
import Image from '../../../../../atoms/Image/Image'
import InfoBox from '../../../../../components/InfoBox/InfoBox'

// Utils & Styles
import './CellMemberSendPasswordModal.scss'
import IconTypes from '../../../../../utils/types/IconTypes'
import { hashPassword } from '../../../../../utils/security/encrypt'
import { encrypt } from '../../../../../utils/security/crypto/pgp'

type Props = {
  title: string
  error?: boolean
  visible: boolean
  disabled?: boolean
  description: string
  initValue?: boolean
  onValidate: (password) => void
  setVisible: (val: boolean) => void
}

const CellMemberSendPasswordModal: React.FC<Props> = ({
  title,
  error,
  visible,
  description,
  disabled = false,
  initValue = false,
  onValidate,
  setVisible,
}) => {
  const t = useLocalize()
  const [password, setPassword] = useState('')

  const handleCloseModal = useCallback(() => visible && setVisible(false), [setVisible, visible])
  const handleValidate = useCallback(() => {
    onValidate(password)
  }, [onValidate, password])

  useEffect(() => {
    setVisible(initValue)
  }, [initValue, setVisible])

  return (
    <IonModal
      isOpen={visible}
      className='CellMemberSendPasswordModal'
      onWillDismiss={handleCloseModal}
    >
      <Icon className='close' icon={IconTypes.X} onClick={handleCloseModal} />
      <div className='title'>{t(title)}</div>
      <div className='box'>
        <InfoBox description={t(description)} />
        <Image src='phone_mail_password' />
        <TextInput
          label={t('password')}
          placeholder={t('password')}
          maxLength={50}
          valueKey='password'
          onChange={setPassword}
          error={error}
          type={'password'}
        />
        <div className='buttonBox'>
          <Button disabled={disabled} fill label={t('validate')} onClick={handleValidate} />
        </div>
      </div>
    </IonModal>
  )
}

// export const CellMemberSealingModal = () => {
//   const { shouldSeal, showModal, waitingSeal, validatePassword, error, setShowPassword } = useSeal()

//   return (
//     <CellMemberSendPasswordModal
//       error={error}
//       initValue={shouldSeal}
//       visible={shouldSeal && showModal}
//       setVisible={setShowPassword}
//       disabled={waitingSeal}
//       title='modal_sealing_title'
//       description='modal_sealing_info'
//       onValidate={validatePassword}
//     />
//   )
// }

export const CellMemberUnsealingModal = () => {
  const {
    shouldUnseal,
    showModal,
    waitingUnseal,
    validatePassword,
    error,
    setShowPassword,
    unSealerPublicKey,
  } = useUnseal()

  const handleSendPassword = async (password) => {
    if (typeof password !== 'string') {
      throw new Error('Password must be a string.')
    }
    const hashedPassword = hashPassword(password)
    const encryptedPassword = await encrypt(password, unSealerPublicKey)

    validatePassword(hashedPassword, encryptedPassword)
  }

  return (
    <CellMemberSendPasswordModal
      error={error}
      initValue={shouldUnseal}
      visible={shouldUnseal && showModal}
      setVisible={setShowPassword}
      title='modal_unsealing_title'
      disabled={waitingUnseal}
      description='modal_unsealing_info'
      onValidate={handleSendPassword}
    />
  )
}

export default CellMemberSendPasswordModal
