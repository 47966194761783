import useLocalize from '../../../../../locales/hook'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IonModal } from '@ionic/react'
import Icon from '../../../../../atoms/Icon/Icon'
import Button from '../../../../../atoms/Button/Button'
import TextInput from '../../../../../atoms/TextInput/TextInput'
import InfoBox from '../../../../../components/InfoBox/InfoBox'
import { LoadingSVG, ValidationSVG, ErrorSVG } from '../../../../../atoms/Svg/Animation'

import './CreatePasswordModal.scss'
import IconTypes from '../../../../../utils/types/IconTypes'
import { calculatePasswordEntropy } from './PasswordEntropy'

type Props = {
  title: string
  error?: boolean
  visible: boolean
  disabled?: boolean
  description: string
  initValue?: boolean
  onConfirm: (password: string) => void
  onClose: () => void
  validate?: boolean
  minLevel?: string
  minLength?: number
  levels?: { [key: string]: number }
  system?: 'level' | 'classic' // level : niveau d'entropie, classic : classique
  selectedMode: 'normal' | 'passphrase' // normal : mot de passe classique, passphrase : phrase de passe
  minSpecialChars?: number
  minUppercaseChars?: number
}

const PASSWORD_LEVELS = {
  Weak: { name: 'Weak', color: 'red', width: '25%' },
  Enough: { name: 'Enough', color: 'orange', width: '70%' },
  High: { name: 'High', color: 'green', width: '100%' },
}

const defaultLevels = {
  Weak: 0,
  Enough: 70,
  High: 90,
}

const CreatePasswordModal: React.FC<Props> = ({
  title,
  error,
  visible = true,
  description,
  disabled = false,
  initValue = false,
  onConfirm,
  validate = false,
  onClose,
  minLevel = PASSWORD_LEVELS.Enough.name,
  minLength = 8,
  levels = defaultLevels,
  system = 'classic',
  selectedMode = 'normal',
  minSpecialChars = 1,
  minUppercaseChars = 1,
}) => {
  const t = useLocalize()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordMatch, setPasswordMatch] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState('')
  const [passwordValid, setPasswordValid] = useState(false)

  const passModes = useMemo(
    () => [
      { value: 'normal', label: t('normal') },
      { value: 'passphrase', label: t('pass phrase') },
    ],
    [t]
  )
  const [passphraseError, setPassphraseError] = useState(false)
  const [passphraseConfirmError, setPassphraseConfirmError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {
    setLoading(true) // Activer le chargement

    setTimeout(async () => {
      try {
        await onConfirm(password) // Votre opération asynchrone
      } catch (error) {
        console.error(error) // Gérer les erreurs si nécessaire
      } finally {
        setLoading(false) // Désactiver le chargement une fois terminé
      }
    }, 0)
  }

  const isPassphraseInvalid = passphraseConfirmError && selectedMode === 'passphrase'

  useEffect(() => {
    setPasswordMatch(password === confirmPassword && password !== '')
    const entropy = calculatePasswordEntropy(password)

    let strength = ''
    if (password.length > 0) {
      strength = PASSWORD_LEVELS.Weak.name

      if (system === 'classic') {
        const specialCharCount = (password.match(/[^a-zA-Z0-9]/g) || []).length
        const uppercaseCharCount = (password.match(/[A-Z]/g) || []).length
        // Conditions pour "Enough"
        if (
          password.length >= minLength &&
          specialCharCount >= minSpecialChars &&
          uppercaseCharCount >= minUppercaseChars
        ) {
          strength = PASSWORD_LEVELS.Enough.name
        }
        // Conditions pour "High"
        if (
          password.length >= minLength + 3 &&
          specialCharCount >= minSpecialChars &&
          uppercaseCharCount >= minUppercaseChars
        ) {
          strength = PASSWORD_LEVELS.High.name
        }
      } else {
        for (const level in levels) {
          if (entropy >= levels[level]) {
            strength = level
          }
        }
      }
    }
    setPasswordStrength(strength)

    let isValid = false
    if (selectedMode === 'passphrase') {
      isValid = password.length >= minLength && entropy >= levels[minLevel]
    } else if (system === 'level') {
      isValid = password.length >= minLength && entropy >= levels[minLevel]
    } else if (system === 'classic') {
      const specialCharCount = (password.match(/[^a-zA-Z0-9]/g) || []).length
      const uppercaseCharCount = (password.match(/[A-Z]/g) || []).length
      isValid =
        password.length >= minLength &&
        specialCharCount >= minSpecialChars &&
        uppercaseCharCount >= minUppercaseChars
    }
    setPasswordValid(isValid)
  }, [
    password,
    confirmPassword,
    levels,
    minLevel,
    minLength,
    system,
    minSpecialChars,
    minUppercaseChars,
    selectedMode,
  ])

  const handlePasswordChange = useCallback(
    (value: string) => {
      if (selectedMode === 'passphrase') {
        const lowerValue = value.toLowerCase()
        const sanitizedValue = lowerValue.replace(/[^a-z0-9\s"-]/g, '')
        setPassphraseError(sanitizedValue.length !== lowerValue.length)
        setPassword(sanitizedValue)
      } else {
        setPassword(value)
      }
    },
    [selectedMode]
  )

  const handleConfirmPasswordChange = useCallback(
    (value: string) => {
      if (selectedMode === 'passphrase') {
        const lowerValue = value.toLowerCase()
        const sanitizedValue = lowerValue.replace(/[^a-z0-9\s"-]/g, '')
        setPassphraseConfirmError(sanitizedValue.length !== lowerValue.length)
        setConfirmPassword(sanitizedValue)
      } else {
        setConfirmPassword(value)
      }
    },
    [selectedMode]
  )

  const handleCopyPassword = useCallback(() => {
    navigator.clipboard.writeText(password)
  }, [password])
  const getPasswordStrengthColor = () => {
    return PASSWORD_LEVELS[passwordStrength]?.color || 'transparent'
  }
  const getPasswordStrengthWidth = () => {
    return PASSWORD_LEVELS[passwordStrength]?.width || '0%'
  }

  return (
    <IonModal isOpen={visible} className='CreatePasswordModal' onWillDismiss={onClose}>
      <Icon className='close' icon={IconTypes.X} onClick={onClose} />
      <div className='title'>{t(title)}</div>
      <div className='box'>
        <InfoBox description={t(description)} />

        {loading && <LoadingSVG />}

        {validate && !loading && <ValidationSVG />}

        {error && !loading && <ErrorSVG />}

        <TextInput
          label={t('password')}
          placeholder={t('password')}
          maxLength={50}
          valueKey='password'
          onChange={handlePasswordChange}
          error={(error || passphraseError) && selectedMode === 'passphrase'}
          errorMessage={
            passphraseError && selectedMode === 'passphrase'
              ? t('error_passphrase_message')
              : undefined
          }
          type='password'
          disablePaste={true}
          iconRight={
            !isPassphraseInvalid && passwordMatch && passwordValid ? IconTypes.COPY : undefined
          }
          onIconRightClick={passwordMatch && passwordValid ? handleCopyPassword : undefined}
          lowercase={selectedMode === 'passphrase'}
        />

        <div className='password-strength-bar'>
          <div
            className='password-strength-bar-fill'
            style={{
              width: getPasswordStrengthWidth(),
              backgroundColor: getPasswordStrengthColor(),
            }}
          >
            <span className='password-strength-text'>{t(passwordStrength)}</span>
          </div>
        </div>

        <TextInput
          label={t('confirm_password')}
          placeholder={t('confirm_password')}
          maxLength={50}
          valueKey='confirm_password'
          onChange={handleConfirmPasswordChange}
          error={
            ((confirmPassword !== '' && !passwordMatch) || passphraseConfirmError) &&
            selectedMode === 'passphrase'
          }
          errorMessage={
            passphraseConfirmError && selectedMode === 'passphrase'
              ? t('error_passphrase_message')
              : undefined
          }
          type='password'
          disablePaste={true}
          iconRight={
            !isPassphraseInvalid && passwordMatch && passwordValid ? IconTypes.COPY : undefined
          }
          onIconRightClick={passwordMatch && passwordValid ? handleCopyPassword : undefined}
          lowercase={selectedMode === 'passphrase'}
        />

        <div className='buttonBox'>
          <Button
            disabled={disabled || !passwordValid || !passwordMatch || isPassphraseInvalid}
            fill
            label={t('validate')}
            onClick={handleConfirm}
          />
        </div>
      </div>
    </IonModal>
  )
}

export default CreatePasswordModal
