import { createAsyncThunk } from '@reduxjs/toolkit'
import { setAppLoading, setErrorStatus } from '../app/redux'
import { WHITE_VOTE } from '../../pages/VoteElections/VoteElections'
import { getAxios, getTokenHeaders, patchAxios, setAxios } from '../../utils/lib/requestAxios'
import { encryptAnyBallot, generateBallotPrint } from '../../utils/security/ballot.encrypt'
import { requestScrutin } from '../scrutins/services'

export const requestEditUniPoll = createAsyncThunk<
  any,
  {
    pollId: string
    params: any
  }
>('uninominal/requestEditUniPoll', async ({ pollId, params }, { rejectWithValue, dispatch }) => {
  const response: any = await patchAxios(`/uni/polls/${pollId}`, params, getTokenHeaders())

  if (response.error) {
    return rejectWithValue(response.error.response.status)
  } else {
    return { data: response.data, pollId }
  }
})

export const requestEditUniPollStates = (builder) => {
  // Used At scrutins/redux
  builder.addCase(requestEditUniPoll.fulfilled, (state, { payload: { pollId, data } }) => {
    state.scrutinsById[pollId] = {
      ...(state.scrutinsById[pollId] || {}),
      ...(data || {}),
    }
  })
}

// Builder for requested Uni
export const requestUniScrutinStates = (builder) => {
  builder.addCase(requestScrutin.fulfilled, (state, { payload: { type, data } }) => {
    if (type === 'uni') {
      if (!state.candidatesByUniId[data.id]) state.candidatesByUniId[data.id] = []
      data.candidates.forEach((item) => {
        if (!state.candidatesByUniId[data.id].includes(item.id)) {
          state.candidatesByUniId[data.id].push(item.id)
        }
        state.candidatesById[item.id] = item
      }, [])
    }
  })
}

// Delete Candidate from Uni Poll
export const requestDeleteUniCandidate = createAsyncThunk<
  any,
  {
    pollId: string
    candidateId: number
  }
>('uninominal/requestDeleteUniCandidate', async ({ pollId, candidateId }, { rejectWithValue }) => {
  const response: any = await setAxios(
    `/uni/polls/${pollId}/remove-candidate`,
    { candidateId },
    getTokenHeaders()
  )
  if (response.error) {
    return rejectWithValue(response.error.response.status)
  } else {
    return { candidateId, pollId }
  }
})

export const requestDeleteUniCandidateStates = (builder) => {
  builder.addCase(
    requestDeleteUniCandidate.fulfilled,
    (state, { payload: { pollId, candidateId } }) => {
      state.candidatesByUniId[pollId] = state.candidatesByUniId[pollId].filter(
        (id) => id !== candidateId
      )
    }
  )
}

// Reorder the candidates of a uni poll
export const requestReorderCandidates = createAsyncThunk<
  any,
  { orderedCandidateIds: number[]; pollId: string }
>(
  'uninominal/requestReorderCandidates',
  async ({ orderedCandidateIds, pollId }, { rejectWithValue }) => {
    const response: any = await setAxios(
      `/uni/polls/${pollId}/candidate-positions`,
      { orderedCandidateIds },
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return {
      orderedCandidateIds,
      pollId,
    }
  }
)

export const requestReorderCandidatesStates = (builder) => {
  builder.addCase(
    requestReorderCandidates.fulfilled,
    (state, { payload: { pollId, orderedCandidateIds } }) => {
      state.candidatesByUniId[pollId] = orderedCandidateIds
    }
  )
}

const votePending = {}
// Voting for a Uni
export const requestVoteUninominal = createAsyncThunk<
  any,
  { candidateIds: number[]; pollId: string; callback: () => void; additionalParams: any }
>(
  'uninominal/requestVoteUninominal',
  async (
    { candidateIds, pollId, additionalParams = {}, callback },
    { rejectWithValue, dispatch }
  ) => {
    if (votePending[pollId]) return null
    votePending[pollId] = true
    dispatch(setAppLoading(true))
    const response: any = await getAxios(`/encryption?pollId=${pollId}`, getTokenHeaders())
    if (response.error) {
      votePending[pollId] = false
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error.response.data.message }))
      return rejectWithValue(response.error.response.status)
    }
    const encryptionKey = response.data
    const encryptedBallotContent = await encryptAnyBallot(
      {
        candidateIds: candidateIds.filter((id) => id !== WHITE_VOTE),
      },
      encryptionKey
    )

    const print = await generateBallotPrint(encryptedBallotContent)

    const voteUni: any = await setAxios(
      `/uni/ballots`,
      {
        print,
        pollId,
        encryptedBallotContent,
        ...additionalParams,
      },
      getTokenHeaders()
    )

    votePending[pollId] = false
    if (voteUni.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: voteUni.error.response.data.message }))
      return rejectWithValue(voteUni.error.response.status)
    }
    setTimeout(() => dispatch(requestScrutin({ id: pollId, type: 'uni' })), 250)
    dispatch(setAppLoading(false))
    callback?.()
    return {
      pollId,
      print,
      encryptedBallot: encryptedBallotContent,
      data: voteUni.data,
    }
  }
)

export const requestVoteUninominalStates = (builder) => {
  builder.addCase(requestVoteUninominal.fulfilled, (state, { payload }) => {
    if (!payload) return
    const { pollId, data, print, encryptedBallot } = payload
    state.ballot = {
      print,
      pollId,
      type: 'uni',
      encryptedBallot,
      ...(data || {}),
    }
  })
}
