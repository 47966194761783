import { createSlice } from '@reduxjs/toolkit'
import { requestEditListPollStates } from '../listPolls/services'
import { requestEditResolutionPollStates } from '../resolutions/services'
import { requestEditUniPollStates, requestVoteUninominalStates } from '../uninominal/services'

// Services
import {
  requestScrutinsStates,
  requestScrutinStates,
  requestElectionsStates,
  requestEditScrutinStates,
  requestScrutinsListsState,
  requestCandidatesState,
  requestSetBallotState,
  requestResetBallotState,
  requestGetEncryptedBallotState,
  requestGetUserEncryptionKeysState,
  requestScrutinsElectionsStates,
  requestEditForbiddenPollStates,
  requestForbiddenPollStates,
  requestGetEncryptedUserPrivateKeyState,
} from './services'

// Utils

// Define a type for the slice state
export type ScrutinsState = {
  scrutins: Array<any>
  forbiddenPoll: Array<any>
  scrutinsElections: Array<any>
  elections: Array<any>
  scrutinsById: { [id: string]: any }
  listsById: { [id: string]: any }
  isLoading: boolean
  collegesById: { [id: string]: any }
  errorsById: { [id: string]: any }
  shouldReload: boolean
  userEncryptionKeys: {
    publickey: string
    privatekey: string
    revocationCertificate: string
  } | null
  ballotPrint: string
  encryptedBallot: string
  encryptedUserPrivateKey: string
  ballot: any
  mandatoryVerifications: any
}

// Define the initial state using that type
const initialState: ScrutinsState = {
  elections: [],
  scrutinsElections: [],
  scrutins: [],
  forbiddenPoll: [] as any[],
  scrutinsById: {},
  isLoading: false,
  listsById: {},
  collegesById: {},
  shouldReload: false,
  ballotPrint: '',
  encryptedBallot: '',
  userEncryptionKeys: null,
  encryptedUserPrivateKey: '',
  ballot: {},
  mandatoryVerifications: {},
  errorsById: {},
}

export const scrutins = createSlice({
  name: 'scrutins',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestScrutinStates(builder)
    requestSetBallotState(builder)
    requestResetBallotState(builder)
    requestScrutinsStates(builder)
    requestCandidatesState(builder)
    requestElectionsStates(builder)
    requestEditScrutinStates(builder)
    requestScrutinsListsState(builder)
    requestScrutinsElectionsStates(builder)
    requestGetEncryptedBallotState(builder)
    requestGetEncryptedUserPrivateKeyState(builder)
    requestGetUserEncryptionKeysState(builder)
    requestEditForbiddenPollStates(builder)
    requestForbiddenPollStates(builder)

    // Extern
    requestEditUniPollStates(builder)
    requestEditListPollStates(builder)
    requestVoteUninominalStates(builder)
    requestEditResolutionPollStates(builder)
  },
})
