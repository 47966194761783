import { useCallback, useEffect, useMemo } from 'react'
import {
  requestElections,
  requestScrutins,
  requestScrutin,
  requestEditScrutin,
  requestScrutinsLists,
  requestCandidates,
  requestSetBallot,
  requestGetEncryptedBallot,
  requestScrutinsElections,
  requestReorderScrutins,
  requestEditForbiddenPoll,
  requestForbiddenPoll,
  requestGetUserEncryptionKeys,
  requestResetBallot,
} from './services'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { usePrivileges } from '../auth/hooks'

// Scrutins
export const useElections = (scrutinType: string = 'cse') => {
  const dispatch = useAppDispatch()
  const { CONFIGURE_ELECTION } = usePrivileges()
  const elections = useAppSelector((state) => state.scrutins.elections)

  const isLoading = useAppSelector((state) => state.scrutins.isLoading)

  const getElections = useCallback(
    () => dispatch(requestElections({ canUseAdmin: CONFIGURE_ELECTION })),
    [dispatch, CONFIGURE_ELECTION]
  )

  useEffect(() => {
    if (scrutinType === 'cse') {
      getElections()
    }
  }, [getElections, scrutinType])

  return { elections, isLoading }
}

export const useForbiddenPoll = (scrutinType: string = 'cse') => {
  const dispatch = useAppDispatch()

  const isLoading = useAppSelector((state) => state.scrutins.isLoading)
  const forbiddenPoll = useAppSelector((state) => state.scrutins.forbiddenPoll)

  const getForbiddenPoll = useCallback(
    () => dispatch(requestForbiddenPoll()),
    [forbiddenPoll, dispatch]
  )

  const editForbiddenPoll = useCallback(
    (forbiddenPoll) => dispatch(requestEditForbiddenPoll({ forbiddenPoll })),
    [dispatch]
  )

  return { isLoading, editForbiddenPoll, forbiddenPoll, getForbiddenPoll }
}

export const useScrutinsElections = () => {
  const dispatch = useAppDispatch()

  const { CONFIGURE_ELECTION } = usePrivileges()

  const elections = useAppSelector((state) => state.scrutins.scrutinsElections)
  const isLoading = useAppSelector((state) => state.scrutins.isLoading)

  const getElections = useCallback(
    () => dispatch(requestScrutinsElections({ canUseAdmin: CONFIGURE_ELECTION })),
    [dispatch, CONFIGURE_ELECTION]
  )

  useEffect(() => {
    getElections()
  }, [getElections])

  return { elections, isLoading, getElections }
}

export const useScrutins = () => {
  const dispatch = useAppDispatch()

  const scrutins = useAppSelector((state) => state.scrutins.scrutins)
  const isLoading = useAppSelector((state) => state.scrutins.isLoading)

  const getScrutins = useCallback(() => dispatch(requestScrutins()), [dispatch])

  useEffect(() => {
    getScrutins()
  }, [getScrutins])

  return { scrutins, isLoading, getScrutins }
}

export const useScrutinsIds = () => {
  const scrutins = useAppSelector((state) => state.scrutins.scrutins)
  const scrutinsIds = useMemo(() => scrutins.map(({ id }) => id), [scrutins])

  return { scrutinsIds }
}

export const useScrutin = (
  id: string,
  request: boolean = true,
  type: 'cse' | 'uni' | 'res' | 'list' = 'cse'
) => {
  const dispatch = useAppDispatch()
  const scrutin = useAppSelector((state) => state.scrutins.scrutinsById[id])
  const isLoading = useAppSelector((state) => state.scrutins.isLoading)

  const getScrutin = useCallback(() => {
    if (id) {
      dispatch(requestScrutin({ id, type }))
    }
  }, [dispatch, id, type])

  const editScrutin = useCallback(
    (data) => dispatch(requestEditScrutin({ id, data })),
    [id, dispatch]
  )

  useEffect(() => {
    if (request) getScrutin()
  }, [id, getScrutin, request])

  return { scrutin, editScrutin, getScrutin, isLoading }
}

export const useSetBallot = (pollId: string, type: 'cse' | 'list' | 'res' | 'uni' = 'cse') => {
  const dispatch = useAppDispatch()

  const userEncryptionKeys = useAppSelector((state) => state.scrutins.userEncryptionKeys)
  const encryptedBallot = useAppSelector((state) => state.scrutins.encryptedBallot)
  const encryptedUserPrivateKey = useAppSelector((state) => state.scrutins.encryptedUserPrivateKey)
  const ballotPrint = useAppSelector((state) => state.scrutins.ballotPrint)

  /**
   * Génère une paire de clé pour l'electeur
   */
  const getUserEncryptionKeys = useCallback(() => {
    dispatch(requestGetUserEncryptionKeys({}))
  }, [dispatch, pollId])

  /**
   * Chiffre le bulletin de l'electeur avec la clé publique de l'electeur
   */
  const getEncryptedBallot = useCallback(
    (ballotContent) => {
      dispatch(
        requestGetEncryptedBallot({ ballotContent, userPublicKey: userEncryptionKeys.publicKey })
      )
    },
    [dispatch, userEncryptionKeys]
  )

  /**
   * Envoi le bulletin au serveur pour vote définitif
   */
  const sendBallot = useCallback(
    (additionalBallotParam, callback) =>
      dispatch(
        requestSetBallot({
          pollId,
          print: ballotPrint,
          encryptedBallot,
          userPrivateKey: userEncryptionKeys.privateKey,
          additionalBallotParam,
          type,
          callback,
        })
      ),
    [dispatch, pollId, type, encryptedBallot, encryptedUserPrivateKey, ballotPrint]
  )

  const resetBallot = useCallback(() => dispatch(requestResetBallot()), [dispatch])

  return {
    sendBallot,
    resetBallot,
    getEncryptedBallot,
    getUserEncryptionKeys,
    userEncryptionKeys,
    encryptedBallot,
    ballotPrint,
  }
}

export const useMandatoryVerification = () => {
  const mandatoryVerifications = useAppSelector((state) => state.scrutins.mandatoryVerifications)

  return {
    mandatoryVerifications,
  }
}

// Lists
export const useScrutinLists = (id: string) => {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state) => state.scrutins.isLoading)
  const scrutin = useAppSelector((state) => state.scrutins.scrutinsById[id])

  const getScrutinLists = useCallback(() => dispatch(requestScrutinsLists({ id })), [id, dispatch])

  useEffect(() => {
    getScrutinLists()
  }, [getScrutinLists, id])

  return { scrutin, isLoading }
}

export const useScrutinList = (id: string | number) => {
  const list = useAppSelector((state) => state.scrutins.listsById[id])
  const noVote = id === -1 ? { id: -1 } : {}
  return list || noVote
}

// Candidates
export const useCandidates = (id: number) => {
  const dispatch = useAppDispatch()

  const getCandidates = useCallback(() => dispatch(requestCandidates({ id })), [id, dispatch])

  useEffect(() => {
    !isNaN(Number(id)) && getCandidates()
  }, [getCandidates, id])

  const list = useAppSelector((state) => state.scrutins.listsById[id])
  const userIds = useMemo(() => list?.candidates, [list])
  const candidateIds = useMemo(() => list?.candidateIds, [list])

  return {
    userIds: userIds || [],
    candidateIds: candidateIds || [],
  }
}

export const useAllCandidates = (id: number, allIds: number[]) => {
  const dispatch = useAppDispatch()

  // Use useCallback to create getCandidate
  const getCandidates = useCallback(
    (id) => {
      dispatch(requestCandidates({ id }))
    },
    [dispatch]
  )

  const fetchAllCandidates = () => {
    // Use an array of ids to call getCandidates
    allIds.forEach((id) => {
      getCandidates(id)
    })
  }

  useEffect(() => {
    !isNaN(Number(id)) && fetchAllCandidates()
  }, [id])

  const list = useAppSelector((state) => state.scrutins.listsById[id])
  const userIds = useMemo(() => list?.candidates ?? [], [list])
  const candidateIds = useMemo(() => list?.candidateIds ?? [], [list])

  return {
    userIds: userIds || [],
    candidateIds: candidateIds || [],
  }
}

export const useCollege = (id) => {
  const college = useAppSelector((state) => (id && state.scrutins.collegesById[id]) || null)
  return college
}

export const useBallot = () => {
  const ballot = useAppSelector((state) => state.scrutins.ballot)

  return ballot
}

export const useReorderScrutins = () => {
  const dispatch = useAppDispatch()
  const reorderScrutins = useCallback(
    (orderedPollIds) => dispatch(requestReorderScrutins({ orderedPollIds })),
    [dispatch]
  )

  return reorderScrutins
}
