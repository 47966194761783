export const LoadingSVG = ({ size = 50, color = '#3498db' }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' width={size} height={size}>
        <circle
          cx='50'
          cy='50'
          r='40'
          stroke={color}
          strokeWidth='5'
          fill='none'
          strokeLinecap='round'
          strokeDasharray='250'
          strokeDashoffset='0'
        >
          <animate
            attributeName='stroke-dashoffset'
            from='0'
            to='500'
            dur='2s'
            repeatCount='indefinite'
          />
          <animateTransform
            attributeName='transform'
            type='rotate'
            from='0 50 50'
            to='360 50 50'
            dur='2s'
            repeatCount='indefinite'
          />
        </circle>
      </svg>
    </div>
  )
}
export const ValidationSVG = ({ size = 50, color = '#2ecc71' }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' width={size} height={size}>
        {/* Cercle de validation */}
        <circle cx='50' cy='50' r='45' fill='none' stroke={color} strokeWidth='5' />

        {/* Coche de validation */}
        <path
          d='M30 50 L45 65 L70 35'
          fill='none'
          stroke={color}
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <animate
            attributeName='stroke-dasharray'
            from='0,100'
            to='100,0'
            dur='0.5s'
            fill='freeze'
          />
        </path>
      </svg>
    </div>
  )
}

export const ErrorSVG = ({ size = 50, color = '#f44336' }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' width={size} height={size}>
        {/* Cercle d'erreur */}
        <circle cx='50' cy='50' r='45' fill='none' stroke={color} strokeWidth='5' />

        {/* Croix d'erreur */}
        <g>
          <line
            x1='30'
            y1='30'
            x2='30'
            y2='70'
            stroke={color}
            strokeWidth='5'
            strokeLinecap='round'
          >
            <animate attributeName='x2' from='30' to='70' dur='0.5s' fill='freeze' />
          </line>
          <line
            x1='30'
            y1='70'
            x2='30'
            y2='30'
            stroke={color}
            strokeWidth='5'
            strokeLinecap='round'
          >
            <animate attributeName='x2' from='30' to='70' dur='0.5s' fill='freeze' />
          </line>
        </g>
      </svg>
    </div>
  )
}
