import { md, pki, util } from 'node-forge'
import sha256 from 'crypto-js/sha256'

export class BallotContent {
  listId: number | string | null
  crossedOutCandidateIds: number[]
  voteSector: string

  constructor(stringContent: string) {
    const content = JSON.parse(stringContent)
    this.listId = content.listId
    this.crossedOutCandidateIds = content.crossedOutCandidateIds
    this.voteSector = content.voteSector
  }
}
export const encryptBallot = async (
  ballotContent: BallotContent,
  publicKey: string
): Promise<string> => {
  return encryptData(JSON.stringify(ballotContent), publicKey)
}

export const encryptAnyBallot = async (ballotContent: any, publicKey: string): Promise<string> => {
  return encryptData(JSON.stringify(ballotContent), publicKey)
}

/**
 * generates a print for the encrypted ballot.
 */
export const generateBallotPrint = async (encryptedBallot: string): Promise<string> => {
  return sha256(encryptedBallot).toString()
}
// on the client side, the ballots are encrypted by the frontend with the public key at the time of voting
export const encryptData = async (value: string, key: string): Promise<string> => {
  const publicKey = pki.publicKeyFromPem(key)

  return util.encode64(publicKey.encrypt(value, 'RSA-OAEP'))
}
//  Hachage SHA256 du mot de passe
export const hashPassword = (password) => {
  const sha256 = md.sha256.create()
  sha256.update(password, 'utf8')
  return sha256.digest().toHex()
}
