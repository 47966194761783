import { createSlice } from '@reduxjs/toolkit'

// Services
import {
  requestCellMembersStates,
  requestChangeCellMembersStates,
  requestGetSealingState,
  requestMeSealingState,
  requestMeUnsealState,
  requestSendContactInformationsState,
  requestSetSealingState,
  requestUsersStates,
  requestRandomCellMemberStates,
  requestCreateSealMemberCredentialsState,
  requestCellMemberCountsState,
  requestCreateUnSealerCredentialsState,
  requestUnSealerPublicKeyState,
} from './services'

// Define a type for the slice state
export type SealingState = {
  isSealed: boolean
  isLoading: boolean
  sealMemberCredentialsError: boolean
  unSealerCredentialsError: boolean
  unSealerPublicKey: string | null
  userIds: string[]
  potentialUserIds: string[]
  potentialUserCell: any
  cellMembersIds: string[]
  cellMembersById: any
  me?: any
  shouldSendInformations: boolean
  shouldSendCredentials: boolean
  shouldSeal: boolean
  shouldUnseal: boolean
  memberConfirmedCredentials: boolean
  unSealerConfirmedCredentials: boolean
  shouldShowPassword: boolean
  isGenerating: boolean
  isGeneratingPercentage: null | number
  keys: any[] | null
  errorKeys: string | null
  shouldSendInformationsEmailError: boolean
  shouldSendInformationsPhoneError: boolean
  userIdsCellMemberSelection: Array<number | string>
  cellMemberCount: number
  minimumCellMemberNumber: number
}

// Define the initial state using that type
const initialState: SealingState = {
  isSealed: false,
  memberConfirmedCredentials: false,
  unSealerConfirmedCredentials: false,
  unSealerCredentialsError: false,
  userIds: [],
  potentialUserIds: [],
  potentialUserCell: [],
  cellMembersIds: [],
  cellMembersById: {},
  isLoading: false,
  me: null,
  unSealerPublicKey: null,
  shouldSendInformations: false,
  shouldSendCredentials: false,
  shouldSeal: false,
  shouldUnseal: false,
  shouldShowPassword: false,
  sealMemberCredentialsError: false,
  isGenerating: false,
  isGeneratingPercentage: null,
  keys: null,
  errorKeys: null,
  shouldSendInformationsEmailError: false,
  shouldSendInformationsPhoneError: false,
  userIdsCellMemberSelection: [],
  cellMemberCount: 0,
  minimumCellMemberNumber: 0,
}

export const sealing = createSlice({
  name: 'sealing',
  initialState,
  reducers: {
    setSealMemberCredentialsError: (state, { payload: value }) => {
      state.sealMemberCredentialsError = value
    },
    setMemberConfirmedCredentials: (state, { payload: value }) => {
      state.memberConfirmedCredentials = value
    },
    setUnSealerCredentialsError: (state, { payload: value }) => {
      state.unSealerCredentialsError = value
    },
    setUnSealerConfirmedCredentials: (state, { payload: value }) => {
      state.unSealerConfirmedCredentials = value
    },
    setShouldSendInformations: (state, { payload: value }) => {
      state.shouldSendInformations = value
    },
    setShouldSendCredentials: (state, { payload: value }) => {
      state.shouldSendCredentials = value
    },
    setShouldSendInformationsEmailError: (state, { payload: value }) => {
      state.shouldSendInformationsEmailError = value
    },
    setShouldSendInformationsPhoneError: (state, { payload: value }) => {
      state.shouldSendInformationsPhoneError = value
    },
    setShouldSealStatus: (state, { payload: value }) => {
      state.shouldSeal = value
    },
    setShouldUnsealStatus: (state, { payload: value }) => {
      state.shouldUnseal = value
    },
    setShouldShowPassword: (state, { payload: value }) => {
      state.shouldShowPassword = value
    },
    setGeneratingKeys: (state, { payload: value }) => {
      state.isGenerating = value
    },
    setGeneratingPercentage: (state, { payload: value }) => {
      state.isGeneratingPercentage = value
    },
    setClearKeys: (state) => {
      state.keys = null
    },
    setOverrideCellMemberUsers: (state, { payload: data }) => {
      state.userIdsCellMemberSelection = data || {}
    },
  },
  extraReducers: (builder) => {
    requestUsersStates(builder)
    requestMeUnsealState(builder)
    requestMeSealingState(builder)
    requestUnSealerPublicKeyState(builder)
    requestSetSealingState(builder)
    requestGetSealingState(builder)
    requestCellMembersStates(builder)
    requestChangeCellMembersStates(builder)
    requestSendContactInformationsState(builder)
    requestRandomCellMemberStates(builder)
    requestCreateSealMemberCredentialsState(builder)
    requestCreateUnSealerCredentialsState(builder)
    requestCellMemberCountsState(builder)
  },
})

export const {
  setClearKeys,
  setShouldSealStatus,
  setShouldUnsealStatus,
  setShouldShowPassword,
  setGeneratingKeys,
  setGeneratingPercentage,
  setShouldSendInformations,
  setShouldSendInformationsEmailError,
  setShouldSendInformationsPhoneError,
  setOverrideCellMemberUsers,
  setSealMemberCredentialsError,
  setShouldSendCredentials,
} = sealing.actions
