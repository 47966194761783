import CryptoJS from 'crypto-js'
import { md, pki } from 'node-forge'

const SECRET = 'Random value to be set'

export const generateRSAKeyPair = () => {
  const keypair = pki.rsa.generateKeyPair({
    bits: parseInt(process.env.DEFAULT_RSA_LENGTH ?? '2048'),
  })
  return {
    privateKey: pki.privateKeyInfoToPem(
      pki.wrapRsaPrivateKey(pki.privateKeyToAsn1(keypair.privateKey))
    ),
    publicKey: pki.publicKeyToPem(keypair.publicKey),
  }
}

export const encryptDataWithAES = (value, secret = SECRET) =>
  CryptoJS.AES.encrypt(typeof value === 'object' ? JSON.stringify(value) : value, secret).toString()

export const decryptDataWithAES = (token) => {
  const bytes = CryptoJS.AES.decrypt(token, SECRET)
  const data = bytes.toString(CryptoJS.enc.Utf8)

  try {
    // In case the value is an object
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}

//  Hachage SHA256 du mot de passe
export const hashPassword = (password) => {
  const sha256 = md.sha256.create()
  sha256.update(password, 'utf8')
  return sha256.digest().toHex()
}
