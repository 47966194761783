import * as openpgp from 'openpgp'

/**
 * Génération d'une paire de clé PGP
 * @param keyExpirationTime Durée de validité de la clé avant expiration in second (0 = n'expire jamais)
 * @param passphrase Mot de passe de protection de la clé privé
 * @return  {privateKey, publicKey, revocationCertificate } La clé publique, la clé privée et le certificat de révocation
 */
export async function generateKeys(
  params: {
    keyExpirationTime?: number
    passphrase?: string | null
  } = { keyExpirationTime: 0, passphrase: null }
): Promise<openpgp.SerializedKeyPair<string> & { revocationCertificate: string }> {
  const { keyExpirationTime, passphrase } = params

  const options: openpgp.GenerateKeyOptions & { format?: 'armored' } = {
    type: 'rsa', // Type of the key
    rsaBits: 4096, // RSA key size (defaults to 4096 bits)
    userIDs: [{ name: 'keyvote' }], // you can pass multiple user IDs
  }

  if (!!keyExpirationTime) {
    options.keyExpirationTime = keyExpirationTime // 0 = never
  }

  if (!!passphrase) {
    options.passphrase = passphrase
  }

  const { privateKey, publicKey, revocationCertificate } = await openpgp.generateKey(options)

  return { privateKey: btoa(privateKey), publicKey: btoa(publicKey), revocationCertificate }
}

/**
 * Chiffrement PGP à partir d'une clé publique
 * @param contentToEncrypt Le contenu à chiffrer
 * @param publicKey La clé public associée au scrutin
 * @return string Le contenu chiffré
 */
export async function encrypt(contentToEncrypt: string, publicKey: string): Promise<string> {
  //Chiffre le contenu du bulletin avec la clé publique de l'utilisateur
  const encrypted = await openpgp.encrypt({
    message: await openpgp.createMessage({ text: contentToEncrypt }), // input as Message object
    encryptionKeys: await openpgp.readKey({ armoredKey: atob(publicKey) }),
    format: 'armored',
  })
  return btoa(encrypted)
}

/**
 * Déchiffrement PGP à partir d'une clé privé
 * @param encryptedData Le contenu à déchiffrer
 * @param privateKey La clé privé associée au scrutin
 * @return string Le contenu déchiffré
 */
export async function decrypt(
  encryptedData: string,
  privateKey: string,
  passphrase?: string
): Promise<string> {
  const message = await openpgp.readMessage({
    armoredMessage: atob(encryptedData), // parse armored message
  })

  const protectedDecryptionKeys = await openpgp.readPrivateKey({
    armoredKey: atob(privateKey), // parse armored key
  })

  // Si la clé privé est protégé, on la déchiffre avec la passphrase
  let decryptionKeys
  if (!!passphrase) {
    decryptionKeys = await openpgp.decryptKey({
      privateKey: protectedDecryptionKeys,
      passphrase,
    })
  } else {
    decryptionKeys = protectedDecryptionKeys
  }

  // Déchiffrement
  const decrypted = await openpgp.decrypt({
    message,
    decryptionKeys,
    format: 'utf8',
  })
  return decrypted.data
}

/**
 * Vérifie si une clé est expirée
 * @param armoredKey La clé à verifier
 * @return boolean true si la clé est expiré
 */
export async function checkKeyExpiration(armoredKey: string): Promise<boolean> {
  try {
    // Read the key by passing the object correctly to readKey
    const key = await openpgp.readKey({ armoredKey: atob(armoredKey) })

    // Check if the key has expired
    const now = new Date()
    const expirationTime = await key.getExpirationTime()
    if (expirationTime) {
      if (expirationTime > now) {
        console.log('Key expiration time is still valid.')
        return false
      } else {
        console.log('Key has expired.')
        return true
      }
    } else {
      console.log('The key has no expiration date (valid indefinitely).')
      return false
    }
  } catch (error) {
    console.log('Error while checking the key:', error)
    throw error
  }
}
