import { decryptDataWithAES, encryptDataWithAES } from './encrypt'

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.includes('safari') && !ua.includes('chrome')
}

export const setCookie = (key: string, value: string = '', expiration?: string | number | Date) => {
  let expires = ''
  const valueEnc = encryptDataWithAES(value)

  if (expiration) {
    const date = new Date(expiration)
    expires = `; expires=${date.toUTCString()}`
  }

  const time = new Date()

  if (isSafari()) localStorage.setItem(key, valueEnc)
  else
    document.cookie = `${key}=${valueEnc};time=${time.toUTCString()}${expires}; path=/; secure; samesite=strict;`
}

export const getCookie = (key: string) => {
  if (isSafari()) {
    const value = localStorage.getItem(key)
    if (!value) return null
    return decryptDataWithAES(value)
  } else {
    const values = document.cookie.split(';')
    const cookie = values.find((cookie) => cookie?.trim()?.startsWith(`${key}=`))

    if (cookie) {
      const cookieValue = cookie.split('=')[1]
      return decryptDataWithAES(cookieValue)
    }
    return null
  }
}

export const deleteCookie = (key: string) => {
  if (isSafari()) {
    localStorage.removeItem(key)
  } else {
    document.cookie = `${key}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }
}

export const deleteAllCookies = () => {
  if (isSafari()) {
    localStorage.clear()
  } else {
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    }
  }
}
