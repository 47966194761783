import { IonItem } from '@ionic/react'
import { useCallback, useEffect, useState } from 'react'
import Icon from '../../../../atoms/Icon/Icon'
import Tooltip from '../../../../atoms/Tooltip/Tooltip'
import useLocalize from '../../../../locales/hook'
import { useCredentialsInformations } from '../../../../redux/sealing/hooks'
import IconTypes from '../../../../utils/types/IconTypes'
import { generateKeys } from '../../../../utils/security/crypto/pgp'
import { hashPassword } from '../../../../utils/security/encrypt'
import CreatePasswordModal from '../../../../pages/Home/user/partials/CreatePasswordModal/CreatePasswordModal'

const CellMemberButton: React.FC<any> = () => {
  const t = useLocalize()

  const {
    setShouldSendCredentials,
    isCellMember,
    shouldSendCredentials,
    memberConfirmedCredentials,
    setCellMemberPassword,
    sealMemberCredentialsError,
  } = useCredentialsInformations()

  const [modalvisible, setModalVisible] = useState(true)

  useEffect(() => {
    setModalVisible(!!shouldSendCredentials)
  }, [shouldSendCredentials])

  useEffect(() => {
    if (memberConfirmedCredentials) {
      setTimeout(() => {
        setModalVisible(false)
      }, 2500)
    }
  }, [memberConfirmedCredentials])

  const handleClick = useCallback(() => {
    if (memberConfirmedCredentials) return

    setShouldSendCredentials(true)
    setModalVisible(true)
  }, [shouldSendCredentials, memberConfirmedCredentials])

  const handleCreatePassword = async (password) => {
    if (typeof password !== 'string') {
      throw new Error('Password must be a string.')
    }
    const keyPair = await generateKeys({ passphrase: password })

    const passwordHash = hashPassword(password)

    setCellMemberPassword(passwordHash, keyPair.publicKey, keyPair.privateKey)
  }

  const handleClose = () => {
    setModalVisible(false)
  }

  if (!isCellMember || !shouldSendCredentials) return null
  return (
    <>
      {!memberConfirmedCredentials && (
        <IonItem lines='none' detail={false} className='headerToggle toggle' onClick={handleClick}>
          <Tooltip vertical='bottom' horizontal='left' label={t('head_credentials_title')}>
            <Icon icon={IconTypes.USER_KEY} />
          </Tooltip>
        </IonItem>
      )}

      <CreatePasswordModal
        visible={shouldSendCredentials && modalvisible}
        error={sealMemberCredentialsError}
        validate={memberConfirmedCredentials}
        title='modal_create_password_title'
        description='modal_create_password_info'
        selectedMode='normal'
        onConfirm={handleCreatePassword}
        onClose={handleClose}
      />
    </>
  )
}

export default CellMemberButton
